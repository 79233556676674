import React from "react"
import { Link as LinkComponent } from "gatsby"
import styled, { css } from "styled-components"

// Styling
import textStyles from "../../styles/textStyles"
import colors from "../../styles/colors"
import { ease, duration } from "../../styles/animations"
import { mq } from "../../styles/utils/mq"

// Types
import { ContainerTheme } from "../../types/generic/Link"

export interface IProps {
  url?: string
  title: string
  color?: string
  target?: string
  type?: "internal" | "external" | "submit"
  theme?: string
  disabled?: boolean
  className?: string
}

// Utils
const getLinkColor = (theme: ContainerTheme) => {
  switch (theme) {
    case "light":
    case "accent":
      return colors.black
    default:
      return colors.white
  }
}

const Link = ({
  url,
  title,
  color,
  target,
  type = "internal",
  theme = "dark",
  disabled,
  className,
}: IProps) => {
  const linkColor = color || getLinkColor(theme as ContainerTheme)

  return (
    <Wrapper theme={theme} disabled={disabled}>
      {type === "internal" && url ? (
        <Internal className={className} to={url}>
          <Text color={linkColor}>{title}</Text>
          <ArrowWrapper>
            <Arrow color={linkColor} />
          </ArrowWrapper>
        </Internal>
      ) : type === "external" ? (
        <External className={className} href={url} target={target}>
          <Text color={linkColor}>{title}</Text>
          <ArrowWrapper>
            <Arrow color={linkColor} />
          </ArrowWrapper>
        </External>
      ) : (
        type === "submit" && (
          <Submit className={className} type="submit" disabled={disabled}>
            <Text color={linkColor}>{title}</Text>
            <ArrowWrapper>
              <Arrow color={linkColor} />
            </ArrowWrapper>
          </Submit>
        )
      )}
    </Wrapper>
  )
}

const ArrowWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 30px;
  overflow: hidden;

  ${mq.from.L`
    height: 36px;
    height: calc(var(--base-fluid-font-size) * 1.8);
  `}

  ${mq.from.XL`
    height: 43px;
    height: calc(var(--base-fluid-font-size) * 2.2);
  `}

  ${mq.from.XXL`
    height: calc(var(--base-fluid-font-size) * 3);
  `}
`

const Arrow = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  background-color: ${({ color }: { color: string }) => color};
  transform: translateX(-43px);
  transition: transform ${ease.default} ${duration.fast}ms,
    background-color ${ease.default} ${duration.fast}ms;

  :before,
  :after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 2px;
    width: 16px;
    background-color: ${({ color }: { color: string }) => color};
    transform-origin: center right;
    transition: background-color ${ease.default} ${duration.fast}ms;
  }

  :before {
    transform: rotate(-45deg) translateX(1px);
  }

  :after {
    transform: rotate(45deg) translateX(1px);
  }

  ${mq.from.XL`
    :before,
    :after {
      /* same with the link font size */
      width: 18px;
      width: calc(18px + (30 - 18) * ((100vw - 1280px) / (1920 - 1280)));
    }
  `}

  ${mq.from.XXL`
    height: 3px;

    :before,
    :after {
      height: 3px;
      /* same with the link font size */
      width: 30px;
      width: calc(var(--base-fluid-font-size) * 1.875);
    }
  `}
`

const Text = styled.span`
  ${textStyles.hyperlink};
  display: inline-block;
  flex-shrink: 0;
  margin-right: 15px;
  color: ${({ color }: { color: string }) => color};

  ${mq.from.XXS`
  font-size:24px;
`}

  ${mq.from.XS`
font-size:24px;
`}

${mq.from.S`
font-size:24px;
`}

${mq.from.M`
font-size:32px;
`}
`

const sharedStyling = css`
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 0;
  text-decoration: none;
`

const External = styled.a`
  ${sharedStyling}
`

const Internal = styled(LinkComponent)`
  ${sharedStyling}
  padding-top: 30px;
  padding-bottom: 8px;

  ${mq.from.XXS`
  margin-left: 8px ;
 
`}

  ${mq.from.XS`
  margin-left: 8px ;
 
`}
${mq.from.S`
margin-left: 8px ;
 
`}
${mq.from.M`
  padding: 0px 8px;
 
`}

${mq.from.L`
margin-left: 16px ;
 
`}
${mq.from.XL`
margin-left: 16px;
 
`}
${mq.from.XXL`
margin-left: 16px;
 
`}
`

const Submit = styled.button`
  ${sharedStyling};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  background-color: transparent;
  border: none;
`

const Wrapper = styled.div<{
  theme: string | undefined
  disabled: boolean | undefined
}>`
  margin-right: -43px;
  &:hover {
    ${Text} {
      text-decoration: none;
    }
  }

  ${mq.from.M`
    ${({ disabled }: { disabled: boolean | undefined }) =>
      !disabled &&
      `
      &:hover ${Arrow} {
        transform: translateX(-3px);
      }
    `}
    
  `}

  ${mq.from.XXS`
    margin-right: -30px;
    margin-left: -33px;
  `}

  ${mq.from.XS`
    margin-right: -38px;
    margin-left: -33px;
  `}

  ${mq.from.S`
    margin-right: -44px;
    margin-left: -33px;
  `}
  ${mq.from.M`
    margin-right: -30px;
    margin-left: 150px;
    margin-bottom: 150px;
  `}

  ${mq.from.L`
    margin-right: -47px;
    margin-left: 90px;
    margin-bottom: 150px
  `}
`

export default Link
