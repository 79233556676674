// Types
import { ISliceType, IContactBlocks } from "../../types"
import { IVacancy } from "../../types/components/Vacancy"

// Constants
import { URL } from "../../utils/constants/baseConfig"

// Local types
type IPrismicLinkType = "web" | "document"
interface IPrismicLink {
  link_type: IPrismicLinkType
  url?: string
  target?: string
  slug?: string
}

// Utils
export const getTextLines = (text: string[]) =>
  text.map((line: any) => line.text)

export const resolvePrismicDocumentURL = (type: string) => {
  switch (type) {
    case "case_study":
      return "case"
    // All other documents' whose names are the same as the URLs
    default:
      return type
  }
}

export const getPrismicURL = (type: string, slug: string) => {
  const baseURL = resolvePrismicDocumentURL(type)

  return `/${baseURL}/${slug}`
}

export const getLinkParams = (link: IPrismicLink | undefined) => {
  if (!link) {
    return
  }

  const linkType =
    link.link_type && (link.link_type.toLowerCase() as IPrismicLinkType)

  if (linkType === "document") {
    return {
      url: link.slug,
      target: "_self",
    }
  } else {
    if (!link.url) {
      return ""
    }

    const isInternalLink = link.url.includes(URL.website)

    return {
      // If the link is internal, strip out the base URL so it can be used with Gatsby's Link component
      url: isInternalLink ? link.url.replace(URL.website, "") : link.url,
      target: link.target,
    }
  }
}

export const formatBlogPost = (post: any) => {
  return {
    slug: post.slug,
    title: post.data.title.text,
    type: post.data.post_type,
    date: post.data.publication_date,
    image: post.data.blogImg,
  }
}

export const formatVacancy = (vacancy: any): IVacancy => {
  return {
    slug: vacancy.slug,
    title: vacancy.data.title.text,
    type: vacancy.data.vacancy_type,
    date: vacancy.data.publication_date,
  }
}

export const formatContactBlocks = (data: any): IContactBlocks => {
  return {
    detailsTitle:
      data.block_title_1.raw && getTextLines(data.block_title_1.raw),
    detailsCopy: data.block_copy_1,
    detailsLink: {
      name: data.block_link_name_1,
      url: "/vacancies",
    },
    contactTitle:
      data.block_title_2.raw && getTextLines(data.block_title_2.raw),
    contactCopy: data.block_copy_2,
    contactLink: {
      name: data.block_link_name_2,
      url: "/team",
    },
  }
}

// Shared slice formatter
export const formatPrismicData = (slice: any) => {
  switch (slice.type as ISliceType) {
    case "heading":
      return {
        type: slice.type,
        id: slice.id,
        data: {
          value: slice.primary.heading_text,
        },
      }
    case "rich_text":
      return {
        type: slice.type,
        id: slice.id,
        data: slice.primary.richtext_text,
      }
    case "text_block":
      return {
        type: slice.type,
        id: slice.id,
        data: {
          title: slice.primary.text_block_title,
          text: slice.primary.text_block_text,
          linkTitle: slice.primary.text_block_link_title,
          link: getLinkParams(slice.primary.text_block_link),
          alignment:
            slice.primary.text_block_alignment &&
            slice.primary.text_block_alignment.toLowerCase(),
          hasSpacing:
            slice.primary.text_block_has_spacing &&
            slice.primary.text_block_has_spacing.toLowerCase(),
        },
      }
    case "text_columns":
      return {
        type: slice.type,
        id: slice.id,
        data: {
          left: slice.primary.text_columns_left_column,
          right: slice.primary.text_columns_right_column,
        },
      }
    case "text_media":
      return {
        type: slice.type,
        id: slice.id,
        data: {
          title:
            slice.primary.text_media_title.raw &&
            getTextLines(slice.primary.text_media_title.raw),
          copy: slice.primary.text_media_copy,
          media: slice.primary.text_media_media,
          mediaAlignment: slice.primary.text_media_media_alignment,
          mediaHasPadding: slice.primary.text_media_media_padding,
          hasSpacing: slice.primary.text_media_has_spacing,
          borderTop: slice.primary.text_media_top_border,
          borderBottom: slice.primary.text_media_bottom_border,
          linkTitle: slice.primary.text_media_link_title,
          link: getLinkParams(slice.primary.text_media_link),
          exampleLinkTitle: slice.primary.example_link_title,
          exampleLinkText1: slice.primary.example_link_text_1,
          exampleLinkText2: slice.primary.example_link_text_2,
          exampleLinkText3: slice.primary.example_link_text_3,
          exampleLinkUrl1: slice.primary.example_link_url_1,
          exampleLinkUrl2: slice.primary.example_link_url_2,
          exampleLinkUrl3: slice.primary.example_link_url_3,
        },
      }
    case "image":
      return {
        type: slice.type,
        id: slice.id,
        data: {
          ...slice.primary.image,
          size:
            slice.primary.image_size && slice.primary.image_size.toLowerCase(),
          hasSpacing:
            slice.primary.image_has_spacing &&
            slice.primary.image_has_spacing.toLowerCase(),
        },
      }
    case "image_grid":
      const formattedImageGridItems = slice.items.map((item: any) => {
        return {
          title: item.image_grid_title,
          description: item.image_grid_description,
          image: {
            url: item.image_grid_image.url,
            alt: item.image_grid_image.alt,
          },
          link: {
            url: item.image_grid_link_url,
          },
        }
      })

      return {
        type: slice.type,
        id: slice.id,
        data: {
          title: slice.primary.title,
          link: {
            title: slice.primary.image_grid_link_name,
            url: slice.primary.image_grid_link_url,
          },
          featuredItemPosition:
            slice.primary.image_grid_featured_item_position &&
            slice.primary.image_grid_featured_item_position.toLowerCase(),
          items: formattedImageGridItems,
        },
      }
    case "video":
      return {
        type: slice.type,
        id: slice.id,
        data: {
          ...slice.primary.link,
        },
      }
    case "list":
      const formattedListItems = slice.listItems.map(
        (item: any) => item.list_item
      )

      return {
        type: slice.type,
        id: slice.id,
        data: {
          title: slice.primary.list_title,
          items: formattedListItems,
        },
      }
    case "facts":
      const formattedFactItems = slice.factItems.map((item: any) => {
        return {
          title: item.fact_title,
          copy: item.fact_copy,
          sourceName: item.fact_source_name,
          sourceLink: item.fact_source_link,
        }
      })

      return {
        type: slice.type,
        id: slice.id,
        data: {
          title: slice.primary.facts_title,
          items: formattedFactItems,
        },
      }
    case "quote":
      return {
        type: slice.type,
        id: slice.id,
        data: {
          copy: slice.primary.quote_copy,
          author: slice.primary.quote_author,
          size:
            slice.primary.quote_size && slice.primary.quote_size.toLowerCase(),
        },
      }
    case "principle_heading":
      return {
        type: slice.type,
        id: slice.id,
        data: {
          title: slice.primary.heading_title.text,
          principleNumber: slice.primary.number,
          backgroundImage: slice.primary.background_image,
          mainImage: slice.primary.main_image,
        },
      }
    case "case_cards":
      const formattedCaseCards = slice.items.map((item: any) => {
        return {
          client: item.client,
          industry: item.industry,
          text: item.text.html,
          image: {
            url: item.image.localFile.childImageSharp.fluid,
            alt: item.image.alt,
          },
          link: item.case.uid,
        }
      })
      return {
        type: slice.type,
        id: slice.id,
        data: {
          title: slice.primary.section_title.text,
          linkLabel: slice.primary.link_label,
          items: formattedCaseCards,
          hasSpacing: "off",
        },
      }
    case "case_references":
      const formattedCaseReferenceItems = slice.caseReferenceItems.map(
        (item: any) => {
          return {
            number: item.case_reference_number,
            title:
              item.case_reference_title.raw &&
              getTextLines(item.case_reference_title.raw),
            copy: item.case_reference_copy,
            linkName: item.case_reference_link_name,
            link: {
              url: `/case/${item.reference_case_linked_case.uid}`,
            },
            media: item.case_reference_media,
            mediaHasPadding: item.case_reference_media_padding,
          }
        }
      )

      return {
        type: slice.type,
        id: slice.id,
        data: {
          ...slice.primary,
          items: formattedCaseReferenceItems,
        },
      }
    case "tribe":
      const formattedTeamMembers = slice.tribeItems.map((item: any) => {
        return {
          name: item.team_member.document[0].data.name,
          jobTitle: item.team_member.document[0].data.job_title,
          thumbnail: item.team_member.document[0].data.photo.thumb,
        }
      })

      return {
        type: slice.type,
        id: slice.id,
        data: {
          name: slice.primary.tribe_name.document[0].data.name,
          items: formattedTeamMembers,
        },
      }
    case "contact_banner":
      return {
        type: slice.type,
        id: slice.id,
        data: {
          title: slice.primary.contact_banner_title,
          details: slice.primary.contact_banner_details,
        },
      }
    case "contact_blocks":
      return {
        type: slice.type,
        id: slice.id,
        data: {
          detailsTitle:
            slice.primary.contact_blocks_details_title.raw &&
            getTextLines(slice.primary.contact_blocks_details_title.raw),
          detailsCopy: slice.primary.contact_blocks_details_copy,
          contactTitle:
            slice.primary.contact_blocks_contact_title.raw &&
            getTextLines(slice.primary.contact_blocks_contact_title.raw),
          contactCopy: slice.primary.contact_blocks_contact_copy,
        },
      }
    case "dive_in":
      return {
        type: slice.type,
        id: slice.id,
        data: {
          title: slice.primary.title,
          text: slice.primary.text,
          image: slice.primary.image,
        },
      }
    case "text___rounded_image":
      return {
        type: slice.type,
        id: slice.id,
        data: {
          title: slice.primary.title,
          copy: slice.primary.copy,
          heading: slice.primary.heading,
          contactName: slice.primary.contact_name,
          contactTitle: slice.primary.contact_title,
          underlinedParagraph: slice.primary.underlined_paragraph,
          image: slice.primary.rounded_image,
        },
      }
    default:
      return null
  }
}
